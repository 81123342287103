.login{
    display: block;
    position: relative;
    height: 100%;
}
.login:before{
    content: '';
    background: url("../styles/images/svg/hero.svg");
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.content-login{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-login{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    color: white;
    padding:20px 0;
    height: 400px;
}
.header-login{
    height: 258px;
    background: linear-gradient(270deg, #1070B3 0%, #1A7FBD 10.98%, #0B835A 42.49%, #18A44A 57.33%, #BF3C2A 89.45%, #F16D3B 100%);
}
.body-login{
    top: 5vh;
    position: relative;
    margin: auto;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    position: relative;
}
.body-login.small{
    width: 530px;
    height: 350px;
}
.body-login.big{
    width: 600px;
    margin-top: -70px;
    height: 500px;
}
.logo-login{
    margin-top:34px;
    margin-left: 30px;
}
.divideForm{
    font-size: 25px;
    font-weight: bold;
    margin: 0 20px;
    z-index: 1;
}
.benvenuto-inserisci {
    height: 20px;
    color: #000000;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0.7px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 25px;
}

.benvenuto-inserisci span {
    color: #128186;
    font-weight: bold;
}

.form-group>label{
    color: #666666;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 27px;
}

.row-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.row-form.login .form-group {
    width: 270px;
}
.row-form.big > div{
    width: 100%;
}
.row-form.loginStayConn, .row-form.loginPrivacy {
    display: flex;
    align-items: center;
}
.row-form.loginStayConn span {
    color: #5e5e5e;
    font-size: 14px;
    margin-right: 10px;
    width: 150px;
}
.row-form.loginPrivacy {
    margin-top: 15px;
}
.row-form.loginPrivacy span{
    color: #5e5e5e;
    font-size: 14px;
    margin-right: 10px;
    width: 350px;
}
.eye {
    margin-left: -50px;
}


.loginSubmit .btn{
    width: 150px;
}

.notAccount span {
    font-size: 14px;
    color: #5e5e5e;
}
.notAccount span strong {
    color: #128186;
    cursor: pointer;
}

.notAccount span a {
    text-decoration: none;
}

.notAccount span a:hover {
    text-decoration: underline;
}

.lostPassword span {
    font-size: 14px;
    color: #5e5e5e;
}
.lostPassword span strong {
    color: #128186;
    cursor: pointer;
}

.lostPassword span a {
    text-decoration: none;
}

.lostPassword span a:hover {
    text-decoration: underline;
}

.errorSignin{
    margin-top: -15px;
}

.uppercase{
    text-transform: uppercase;
}