.dropdownMultipleSelect {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: inherit;
  width: inherit;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-toggle {
  text-align: left;
  padding-left: 0;
}

.dropdown:hover {
  background-color: #f5f5f5;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #999;
}


.dropdownMultipleSelect-content {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 200px;
  overflow-y: auto;
  font-weight: normal;
  cursor: pointer;
}

.dropdownMultipleSelect-option:hover {
  background-color: #f5f5f5;
}


.dropdownMultipleSelect-option+.selected {
  background-color: #6edfb4;
  color: #fff;
}

.dropdownMultipleSelect-footer {
  text-align: right;
  color: #C9E9EA;
}
.dropdownMultipleSelect-footer > button{
  font-size: small;
}

/* .dropdownMultipleSelect-footer>.confirm-button {
  color: #6edfb4;
  font-size: larger;
  font-weight: bolder;
} */