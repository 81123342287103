.resultLoader{
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.164);
    z-index: 99999999;
}
.resultLoader .loader {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: calc(50% - 125px);
    background: rgba(0,0,0,.1);
    border-radius: 20px;
    padding: 30px;
 }
.resultLoader .loader .testo{
    color: white;
    margin-top: 20px;
}
