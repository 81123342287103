.react-confirm-alert{
    width: initial !important;
}
.popup.communication {
    width: 100% !important;
}
.alert-header {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0;
    background: #eeeded;
}
.alert-body {
    padding: 10px 0;
    border-bottom: 1px solid;
    border-color: #eeeded;
    font-size: 14px;
    margin-left: 0px !important;
    margin-right: 0 !important;
}
.alert-body:hover{
    background: #fafafa;
}
.alert-body .files{
    display: inline-flex;
    justify-content: center;
}
.alert-body .files svg{
    margin-right: 10px;
    cursor: pointer;
}
