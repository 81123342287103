.form-row {
    padding: 0 10px;
    display: flex;
}

.form-row.center {
    justify-content: center;
}

.form-group label {
    font-weight: bold;
    color: #128186 !important;
    margin: 0px;
}

.form-check {
    padding: initial !important;
}

.panel {
    padding: 20px 5px 0px 5px;
    border: 1px solid #c9e9ea;
    border-radius: 20px;
    max-width: 50%;
    background: #e6f4f4;
    -webkit-box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
    -moz-box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
    box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
}

.panel-note {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 20px 5px 0px 5px;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.panel-note label {
    color: #128186 !important;
}

.panel-note .note {
    margin-bottom: 30px;
    width: 100%;
    height: 100%;
}

.panel.small {
    width: 25%;
}

.primary {
    display: flex;
    padding: 0 10px;
}

.secondary {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.inputDate {
    width: 30%;
    padding: 0 15px;
}

.inputText {
    width: 40%;
    padding: 0 15px;
}

.inputText.small {
    padding: 0 15px;
    width: 30%;
}

.inputSelect {
    width: 100%;
    padding: 0 15px;
}

.inputCheck {
    padding: 0 15px;
    width: 100%;
    margin-top: 20px;
}

.inputRadio {
    font-size: 14px;
}

.inputRadio:hover label {
    text-decoration: underline;
}

.tab-content.relationship .tabs {
    -webkit-box-shadow: -5px 5px 9px 0px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: -5px 5px 9px 0px rgba(0, 0, 0, 0.19);
    box-shadow: -5px 5px 9px 0px rgba(0, 0, 0, 0.19);
}

.input-esit {
    border: none;
    background-color: #ffffff00;
    font-size: 120%;
    text-align: right;
}

.input-identifier {
    border: none;
    background-color: #ffffff00;
    text-align: right;
}

.panel-info {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    color: #5e5e5e;
}

.panel-info.modal {
    position: absolute;
    max-width: initial;
    justify-content: flex-end;
    align-items: flex-end;
    right: 0;
    bottom: 0;
}

.panel-info h5 {
    font-size: 12px;
    margin-bottom: 3px;
}

.panel-info h6 {
    margin-bottom: 12px;
    font-size: 10px;
}

.modal-header {
    position: relative;
}

/*Delete relationship, Update end Date and set comunication cheched */
.updateEndDate {
    display: flex;
}

.updateEndDate .react-datepicker-wrapper {
    display: flex;
}

.updateEndDate,
.updateCoomType {
    cursor: pointer;
    font-weight: normal;
    color: #128186;
}

.updateEndDate svg,
.updateCoomType svg {
    margin-right: 7px;
}

.updateEndDate.disabled {
    color: #6c757d;
    opacity: .65;
}

.updateCoomType.disabled {
    color: #6c757d;
    opacity: .65;
}

.dropdown a:hover {
    text-decoration: none;
}

.form-relationship-button {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*background: white;*/
}

.nav-tabs .nav-link.disabled {
    border-color: rgba(238, 238, 238, 0.5) !important;
}

.visible-enter {
    opacity: 0.01;
    height: 0px;
    overflow: hidden;
}

.visible-enter-active {
    opacity: 1;
    height: 550px;
    transition: all 300ms;
}

.visible-exit {
    opacity: 0;
    height: 500px;
    transition: all 300ms;
}

.visible-exit-active {
    opacity: 0;
    height: 0px;
    transition: all 300ms;
}

/* 
.balancePopOver .btn-primary {
    position: relative;
}
.balancePopOver .btn:disabled {
    color: #FFF !important;
}
.balancePopOver .btn:disabled:hover:after {
    visibility: visible;
    font-weight: normal;
}
.balancePopOver .btn:disabled:hover:before {
    visibility: visible;
    font-weight: normal;
}

.balancePopOver .btn-primary:after{
    visibility: hidden;
    background: #c9e9ea;
    content: "Saldi non ammessi per questa tipologia di rapporto";
    position: absolute;
    width: 350px;
    height: 30px;
    padding: 4px;
    border-radius: 15px;
    border: 1px solid #c9e9ea;
    top: -45px;
    left: -270px;
    color: #000;
    text-align: center;
}

.balancePopOver .btn-primary:before {
    visibility: hidden;
    position: absolute;
    content: '';
    top: -18px;
    left: 60px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 0;
    border-color: #c9e9ea transparent transparent transparent;
} */