.lowRisk {
    border-width:1px;  
    border-style:solid;
    border-color: darkgrey;
    height: 30px;
    width: 100%;
    background-color: rgba(6, 248, 46, 0.836);
  }
.mediumRisk {
    border-width:1px;  
    border-style:solid;
    border-color: darkgrey;
    height: 30px;
    width: 100%;
    background-color: rgba(247, 243, 20, 0.877);
  }
 .highRisk {
    border-width:1px;  
    border-style:solid;
    border-color: darkgrey;
    height: 30px;
    width: 100%;
    background-color: rgba(236, 24, 24, 0.815);
  }

  
  .modal-content {
    min-height: 50vh;
  }

  .modal-content-sm .modal-content {
    min-height: 30vh;
    
  }


  .divider{
    border-bottom: 1px solid black;
  }
  .arco-new-operation{
    min-height: 100px;
    max-width: 100%;
  }
  .divisore {
    height:1px;
    border-width:0;
    color: black;
    background-color: black;
  }

  .testo {
    font-size: 16px;
    font-weight: bold;
    color: #128186 !important;
    margin: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }

fieldset.border {
  border: 1px solid rgb(185, 182, 182) !important;
}

.arco-registry-modal .modal-content{
  min-height: 100vh
}

.arco-modal-max {
  max-height: 95vh;
  position: absolute;
}

.big-modal {
  max-height: 95vh;
}
.big-modal>.modal-dialog{
  max-width: 95vw;
}

.generic-popup>.modal-dialog>.modal-content{
  min-height: 20vh;
}


.arco-modal-max>.modal-dialog>.modal-content{
  min-height: 84vh;
}

.arco-modal-registries>.modal-dialog>.modal-content {
  min-height: 72vh;
}