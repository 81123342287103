.panel.w-all {
    max-width: 100%;
}

.btn-controllingPerson button svg{
    margin-right: 10px;
}

.btn-controllingPerson button:hover svg {
    fill: #126368;
}
.btn-controllingPerson button.btn-empty:hover svg {
    fill: #fff;
}

.react-autosuggest__suggestions-container {
    width: inherit;
    z-index: 99999;
}

.row-table.crs {
    height: 50px;
}
.row-table.crs .form-group{
    margin: 0 !important;
}
.paymentYear {
    font-size: 30px !important;
}

.tab-rel .show {
    display: block;
}
.tab-rel .hide {
    display: none;
}
.form-group.disabledSelect{
    opacity: .3;
}
