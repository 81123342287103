form.crs {
    position: relative;
}
.soggetto-crs{
    padding: 20px 5px 0px 15px;
    border: 1px solid #c9e9ea;
    border-radius: 20px;
    background: #e6f4f4;
    -webkit-box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
    -moz-box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
    box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
}
.soggetto-crs.big {
    width: 100%;
    min-height: 340px;
}
.soggetto-crs.small {
    width: 100%;
    height: 210px;
}
.soggetto-crs.auto {
    width: 100%;
    height: 100%;
}


.soggetto-crs h5 svg{
    margin-right: 10px;
}
.oicrContainer {
    margin-top: -25px;
}
.oicr {
    display: none;
    margin: 0 auto 10px;
    padding: 20px 10px 10px;
    border-radius: 10px;
    border: 1px solid #bbb;
    position: relative;
}
.oicr span {
    position: absolute;
    top: -10px;
    left: 15px;
    background: #e6f4f4;
    font-size: 16px;
    font-weight: bold;
    color: #128186 ;
}
.oicr.show{
    display: block;
}
.question{
    position: absolute;
    bottom: 10px;
    right: 30px;
}
.question span {
    color: #565656;
}
.question svg {
    cursor: pointer;
    margin-bottom: 25px;
}


.display-none {
    display: none;
}
