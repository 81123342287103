.row-detail.comurei li.tab-list-item, .modal-body li.tab-list-item{
    width: calc(19% - 10px);
}
.row-detail.comurei li.tab-list-item:last-child, .modal-body li.tab-list-item:last-child{
    width: calc(24% - 10px);
}
.commDetailComurei{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.commDetailComurei .form-chek {
    line-height: initial;
}
.commDetailComurei .form-check-label{
    font-size: 14px;
}

.infoPostaEle{
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: rgba(245, 245, 245, 0.51);
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190, 0.85);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    border-radius: 15px;
}
.infoPostaEle.absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}
.infoPostaEle .infoPoint-title{
    font-size: 16px;
    margin-left: 10px;
    color: #b8b8b8;
}
.infoPostaEle .infoPoint-title svg{
    margin-bottom: 3px;
}
.infoPostaEle .infoPoint{
    font-size: 12px;
    margin-bottom: 10px;
    color: #5e5e5e;
}
.infoPostaEle .infoPoint strong{
    text-decoration: underline;
    color: #000;
}
.tab-content.tab-rell {
    position: relative;
}
.tab-content.tab-rel .infoCanc{
    position: absolute;
    top: 105px;
    right: 30px;
    max-width: 600px;
    font-size: 12px;
    color: #5e5e5e;
    line-height: 16px;
}
.tab-content.tab-rel .infoCanc svg{
    position: absolute;
    left: -30px;
}
.tab-content.tab-rel .infoCanc strong{
    text-decoration: underline;
    color: #000;
}

.tab-content.tab-rel .infoSub{
    max-width: calc(60% - 30px);
    font-size: 12px;
    color: #5e5e5e;
    line-height: 16px;
    margin-left: 40px;
}
.tab-content.tab-rel .infoSub svg{
    position: absolute;
    left: 45px;
}
.tab-content.tab-rel .infoSub strong{
    text-decoration: underline;
    color: #000;
}
.form-check > label{
    font-weight: bold;
    color: #128186 !important;
}
.panelCancellazione{
    position: absolute;
    right: 40px;
}
.panelSub {
    width: 61%;
}
.panelSubComurei {
    width: 96%;
}
.panelTrasm {
    width: 45%;
}

.divide-form {
    height: 15px;
    border-bottom: 1px solid #ececec;
    border-bottom-style: solid;
    margin-bottom: 5px;
}



.corproGroup{
    margin-top: 1%;
    margin-right: 1%;
    width: 59%;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    background: rgba(245, 245, 245, 0.51);
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190, 0.85);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    border-radius: 15px;
}
.corproGroup.absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}
.corproGroup .infoPoint-title{
    font-size: 16px;
    margin-left: 10px;
    color: #b8b8b8;
}
.corproGroup .infoPoint-title svg{
    margin-bottom: 3px;
}
.corproGroup .infoPoint{
    font-size: 12px;
    margin-bottom: 10px;
    color: #5e5e5e;
}
.corproGroup .infoPoint strong{
    text-decoration: underline;
    color: #000;
}

.corproGroup2{
    margin-top: 1%;
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    background: rgba(245, 245, 245, 0.51);
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190, 0.85);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    box-shadow: inset 0px 0px 10px 0px rgba(190, 190, 190,0.85);
    border-radius: 15px;
}
.corproGroup2.absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}
.corproGroup2 .infoPoint-title{
    font-size: 16px;
    margin-left: 10px;
    color: #b8b8b8;
}
.corproGroup2 .infoPoint-title svg{
    margin-bottom: 3px;
}
.corproGroup2 .infoPoint{
    font-size: 12px;
    margin-bottom: 10px;
    color: #5e5e5e;
}
.corproGroup2 .infoPoint strong{
    text-decoration: underline;
    color: #000;
}

.comurei-registry-modal .modal-content{
    min-width: 130vh;
  }