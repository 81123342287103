.custom-control-label.star-checkbox::before
,.custom-control-label.star-checkbox::after {
    width:1.5rem!important;
    height: 1.5rem!important;
}
.custom-control-label.star-checkbox{
    cursor: pointer;
    margin-top: -0.25rem;
}
/* .custom-control-input:checked~.custom-control-label::before {
    color: black;

} */

.custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}