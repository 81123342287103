.monthly {
    height: 50px;
    justify-content: flex-start;
    padding-left: 10px;
    -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
    box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
    display: flex;
    align-items: center;
}

.monthly .period-error{
    color:#dc3545!important;
}

.monthly-row .lastError {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.monthly-row .showLastError {
    width: 550px;
    position: absolute;
    top: 0;
    left: -490px;
    display: none;
    background: #f5f5f5;
    border-radius: 20px;
    z-index: 99;
    box-shadow: -4px 4px 10px 1px rgba(240, 82, 82, 0.4);
    -webkit-box-shadow: -4px 4px 10px 1px rgba(240, 82, 82, 0.4);
    -moz-box-shadow: -4px 4px 10px 1px rgba(240, 82, 82, 0.4);
}
.monthly-row .showLastError:after {
    position: absolute;
    content: '';
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #f05252 transparent transparent transparent;

}

.monthly-row .showLastError .lastError-header{
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: white;
    border-top-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background: #f05252;
}
.monthly-row .showLastError .lastError-body {
    padding: 20px;
    -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
    box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.75);
}
.monthly-row .showLastError.show {
    display: block;
}


