
 .inputs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.inputs > .input {
  margin-top: 1.5rem;
  flex: 1 0;
  width: 100%;
  border: 1px solid black;
  background-color: white;
  position: relative;
}

.input__field {
  opacity: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  transition: 200ms opacity ease-out;
  
}
.input__label {
  position: absolute;
  left: 0.5rem;
  right: 0;
  top: 0;
  box-sizing: border-box;
  transform-origin: 0 50%;
  transition: 200ms transform ease-out;
}




/*input con focus e non vuoto*/
.input__field.notEmpty, .input__field:focus {
  opacity: 1;
  transition: 200ms opacity ease-out 100ms;
}

/*input con focus e non vuoto*/
.input__field.notEmpty + .input__label,
 .input__field:focus + .input__label {
  transform: scale(0.9) translateY(-110%);
}



.input__error, .input__info {
  display: block;
  font-size: 0.8em;
  color: red;
  font-weight: bold;
  background-color: transparent;
}

.input__field::-webkit-calendar-picker-indicator{
  display: none;
}

.input__field[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}