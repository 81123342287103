.react-autosuggest__input{
    background:none;
    border: none;
    border-bottom: 1px solid #979797 ;
    color:#000000;
    border-radius: unset;
}
.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 55px;
    width: 100%;
    max-height: 150px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    overflow-y: scroll;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.85em;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}