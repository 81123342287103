.dashboard {
    display: flex;
    justify-content: center;
    padding: 30px 10px;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.panel-dash {
    padding: 0px 5px 30px 5px;
    border: 1px solid #e4e4e4;
    border-radius: 20px;
    max-width: 90%;
    background: #f3f3f3;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.2);
}
.container-dash .dash-title {
    width: 100%;
    margin-top: 25px;
    padding: 0 30px 0 60px;
    z-index: 9;
    font-size: 30px;
    font-weight: bold;
    align-self: flex-start;
}
.container-dash .dash-title svg {
    margin-right: 20px;
}
.container-dash {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.container-dash .card {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: initial;
    background: transparent;
    text-decoration: none;
    color: #000;
    margin-bottom: 20px;
    width: 90%;
    box-sizing: border-box;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.4);
    margin-left: 5%;
    margin-top: 5%;
    height: 150px;
}

.app-name {
    font-size: 30px;
    transition: 0.7s;
    color: #fff;
    font-weight: bold;
}
.app-name span{
    color: #cccccc;
}

.container-dash .card .face {
    width: 100%;
    height: 140px;
}

.container-dash .card .face.face1 {
    background: #3b9aae;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    transition: .3s;
}

.container-dash .card:hover .face.face1{
    background: #115767;
    transition: .3s;
}
.container-dash .card:hover .face.face1 .app-name{
    font-size: 40px;
}
.container-dash .card .face.face2 {
    position: relative;
    background: #eee;
    display: block;
    justify-content: left;
    align-items: center;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    transition: .3s;
    width: 100%;
    text-align: left;
}

.container-dash .card:hover .face.face2{
    transition: .3s;
}

.container-dash .card .face.face2 .content {
    height: 100%;
    padding: 10px;
    width: 100%;
}

.coraStar{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: left;
    padding: 5px 10px;
}
.coraStar-img{
    padding: 3% 0 0 5%;
    width: 200px;
}
.coraStar .coraStar-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.coraStar .coraStar-header .coraStar-title {
    color: #000;
    font-weight: bold;
    font-size: 18px;
}
.coraStar-subtitle {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    padding: 1% 0 0 5%;
}
.coraStar-scadenza {
    font-size: 14px;
    color: #393939;
    text-align: left;
    padding: 1% 0 0 5%;
}


/*NOTITY*/
.dash-notify{
    width: 100%;
    margin-top: 25px;
    padding: 0 30px 0 40px;
    z-index: 9;
}
.notify-title{
    font-size: 30px;
    font-weight: bold;
}
.notify-title svg {
    margin-right: 20px;
}
.notify-row {
    display: flex;
    flex-direction: column;
}
.notify-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.notify-circle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    background: #fff;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px -1px rgba(0,0,0,0.5);
}
.notify-circle .day{
    font-size: 24px;
    font-weight: bold;
    color: #6c0c0c;
    line-height: 24px;
}
.notify-circle .month{
    font-size: 14px;
    color: #4b0202;
}
.notify-circle .year{
    font-size: 12px;
    color: #000;
}
.notify-text {
    font-size: 14px;
    padding-left: 20px;
}

.image-app{
    padding-right: 0 !important;
    padding-left: 0!important;
    max-width: 200px !important;
    width: 200px !important;
    height: 52px !important;
}
