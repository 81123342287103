.error{
    color: red!important;
    font-weight: bold!important;
    font-size: 1.1rem!important;
}

.correct{
    color: green!important;
    font-weight: bold!important;
    font-size: 1.1rem;
}

.normal{
    color: black!important;
    font-weight: 700!important;
    font-size: 1.1rem!important;
}
.form-text {
    margin-top: .25rem;
    font-size: 1.1rem;
}