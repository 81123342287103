.react-confirm-alert-overlay{
  z-index:99999;
}

.react-confirm-alert .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: url("../styles/fonts/Lato-Regular.ttf") format("truetype");
}

code {
  font-family: Lato, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-27 {
  margin-top: 27px;
}

.page-body{
  margin-bottom: 60px;
}

.btn-star{
  border-radius: 20px;
}

.input-group>.form-control, .form-control {
  background: none ;
  border: none;
  border-bottom: 1px solid #979797;
  border-radius: 0;
  color: #000000;
  padding: initial;
  height: initial;
}
.form-control:focus {
 background-color: unset;
  box-shadow: none !important;
}

.form-group label, .form-check>label{
  color: #666666;
  font-size: 1em;
  letter-spacing: 0;
  line-height: 19px;
}

.form-row{
  margin: 0;
}

.react-autosuggest__input {
  padding: initial;
  height: initial;
}

.provInfo{
  font-size: 10px;
  color: #5e5e5e;
}

.form-check .input-group{
  color:#000000 !important;
  font-size: 0.75em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  background-color: #F4F4F4 !important;
  color:#666666 !important;
}


.custom-select-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../styles/images/svgs/solid/angle-down.svg')!important;
  background-color: inherit!important;
  background-repeat: no-repeat!important;
  background-position: right 0.75rem center!important;
  background-size: 8px 25px!important;
  padding-right: 2rem!important;
  text-overflow: ellipsis!important;
}





#header{
  background: linear-gradient(270deg, #1070B3 0%, #1A7FBD 10.98%, #0B835A 42.49%, #18A44A 57.33%, #BF3C2A 89.45%, #F16D3B 100%);
  height: 80px;
  z-index: 1;
}
.logo-dashboard, .logo-app{
  border-radius: 35px;
  padding-left: 5px;
  padding-right: 20px;
  margin-left:10px
}
.logo-app{
  padding-right: 5px;
}
.vertical-line{
  box-sizing: border-box;
  height: 35px;
  width: 1px;
  border: 1px solid #FFFFFF;
}
#navbarContent{
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  display: flex!important;
  flex-basis: auto;
  flex-direction: row;
}
.navbar-nav {
  padding: 0px 10px;
  position: relative;
  flex-direction: row;
  display: flex;
  margin-bottom: 0;
  list-style: none;
}
#navbarContent .nav-item{
  display: flex;
}
#appBox, .customerBox{
  background: #FFFFFF;
  margin-top: 22px;
  width: 450px;
  padding: 0;
  left: 20%;
  border-radius: unset;
  font-size: 1em ;
  border-top: none;
 box-shadow: 2px 1px 10px #ccc
}
 .customerBox ul{
  list-style: unset;
}
#appBox ul li a, .customerBox ul li a{
  color:#FFFFFF;
  font-weight: 800;
}
#appBox ul li .nav-link:hover{
  color: black;
}

.clickable{
  cursor: pointer;
}


.customerBox .customer-info {
  display: flex;
}


.customer-box-overlay, .app-box-overlay{
  z-index: 9999;
}
.app-box-overlay{
  /* hack per posizionare il box app in alto. Non chiaro perché l'overlay non viene posizionato nel posto giusto*/
  transform: translate(200px, 60px)!important;
}


.customerBox .customer-info .customer-user{
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 20px;
  font-size: 20px;
}
.customerBox .customer-info .customer-user .mail {
  font-size: 12px;
  color: #999999;
}
.customerBox .customer-info .customer-user .appInfo {
  margin-top: 5%;
  font-size: 12px;
  color: #999999;
}
.customerBox .customer-info .customer-user .company {
  font-size: 12px;
  color: #999999;
}
.customerBox .logout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #128186;
  height: 40px;
}
.customerBox .logout a {
  color: #fff !important;
  font-weight: bold;
  padding: 0;
  margin-right: 20px;
}

.dropdown-menu-green {
  color: #128186;
}

.menu-icon{cursor:pointer}
.rotate-down{
  transform: rotate(270deg);
  margin: auto;
  margin-left: 10px;
}
#header .dropdown-menu{
  display: block !important;
}

#header li a:hover{
  color: black;
}

.dropdown-customer{
  background: #FFFFFF;
  border-radius: 35px;
  padding-left: 20px;
  padding-right: 5px;
  margin-left:10px;
  cursor: pointer;
}

.footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
}
.footer-bar{ background: linear-gradient(270deg, #1070B3 0%, #1A7FBD 10.98%, #0B835A 42.49%, #18A44A 57.33%, #BF3C2A 89.45%, #F16D3B 100%);
  height:5px;}

.footer a{
  color: #000;
}

.title-page{
  padding-top: 22px;
  padding-bottom: 20px;
  font-size: 1.750em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
}
.title-page a{
  text-decoration: none;
  color: #000;
}
/*TABS HEADER REL*/
.table-custom{
  margin-bottom: 70px;
}
.table-custom .tabs.single ul{
  position: relative;
}
.table-custom .tabs.single > ul:before{
  content: '';
  position: absolute;
  height: 2px;
  border-bottom: 1px solid #128186;
  bottom: 0;
  overflow: hidden;
}
.table-custom.comurei > .tabs > ul {
  position: relative;
}
.table-custom.comurei > .tabs > ul:before{
  content: '';
  position: absolute;
  height: 2px;
  border-bottom: 1px solid #128186;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  width: 50%;
  opacity: .5;
}
.table-custom > div > ul > li.tab-list-item {
  color:#128186 !important;
  width: auto;
  border-color:#128186;
  border-radius: 0;
  background-color: #EBEBEB;
  opacity: 0.5;
  cursor: pointer;
}
.table-custom > div > ul > li.tab-list-active{
  border-top: 5px solid;
  border-color:#128186 !important;
  background-color: #ffffff;
  opacity: unset;
  border-bottom: none;
  font-weight: bold;
}
.table-custom > div > ul.nav-tabs{
  border-bottom: 1px solid #128186 !important;
}
/*FINE TABS HEADER REL*/
.header-btn{
  display: flex;
  height: 90px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 0px !important;
}
.header-btn.small{
  height: 50px;
}
.header-btn button,.header-btn a{
  font-size: 1em;
  color:#128186;
}

.btn-primary:not(:disabled):not(.disabled):active{
  border-color: #126368;
  background-color: #126368;
  box-shadow: 0 0 0 0.2rem rgb(93, 141, 164);
}
.btn-primary:focus {
  border-color: #126368;
  background-color: #126368;
  box-shadow: 0px 0px 5px 0px rgba(14, 49, 54, 0.75);
}


.btn-danger{
  border-color:red;
  color:#ffffff;
}

.btn-danger:hover{
  border-color:red;
  background-color: red;
  color:#ffffff;
}

.btn-empty{
  border-color:#128186;
  color:#128186;
}
.btn-empty:hover{
  border-color:#128186;
  color:#ffffff;
  background-color: #128186;
}

.btn-empty:hover svg{
  border-color:#128186;
  color:#ffffff;
  fill:#ffffff;
  background-color: #128186;
}

.btn-primary:hover svg{
  border-color:#128186;
  color:#128186;
  fill:#128186;
}

.btn-new-rel-transp{
  border-color: transparent;
  color:#ffffff !important;
  background-color: transparent;
}

.btn-new-rel-transp:hover{
  border-color:#128186;
  background-color:#ffffff;
  color:#128186 !important;
}

.btn-new-rel{
  border-color:#128186;
  color:#ffffff !important;
  background-color: #128186;
}
.btn-new-rel.comurei{
  border-color: #ff6868;
  background-color: #ff6767;
}
.btn-new-rel.comurei:hover, .btn-new-rel.comurei:active{
  border-color: #ff6868 !important;
  background-color: #fff !important;
  color: #ff6767 !important;
}
.btn-new-rel.comurei:focus{
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.btn-new-rel:hover{
  border-color:#128186;
  background-color:#ffffff;
  color:#128186 !important;
}

.btn-new-rel svg{
  fill:#ffffff !important;
}

.btn-new-rel:hover svg{
  fill:#128186 !important;
}

.btn-new-rel.disabled:hover svg{
  fill:#ffffff !important;
}


.btn-new-rel.disabled:hover{
  border-color:#128186;
  background-color:#ffffff;
  color:#ffffff !important;
}
/*table list*/
.header-table{
  background-color: #C9E9EA;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border-top: 1px solid #959595;
  border-left: 1px solid #959595;
  border-right: 1px solid #959595;
  border-bottom: 1px solid #959595;
}

.header-table.virtual{
  height: 35px;
}

.header-table.error {
  background-color: rgba(239, 10, 21, .1);
}
.header-table.subrow .div-td{
  font-style: italic;
  font-size: 13px;
}
.header-table.subrow div{
  padding: 7px 5px 7px 8px;
  background-color: #DEDEDE;
}
.header-table div{
  padding: 15px 5px 15px 8px;
  border-right: 1px solid #959595;
}
.row-table{
  display: flex;
  font-size: 0.875em;
  background-color: #ffffff;
  border-bottom: 1px solid #959595;
  cursor: pointer;
  height: 35px;
}
.row-table-xl{
  display: flex;
  font-size: 0.875em;
  background-color: #ffffff;
  border-bottom: 1px solid #959595;
  cursor: pointer;
  min-height: 35px;
}


.arco-new-operation{
    min-height: 100px;
    max-width: 100%
}
.row-table.subrow .div-td{
  padding: 7px 5px 7px 8px;
  font-style: italic;
  height: auto !important;
}
.row-table:hover{
  font-weight: bold;
}
.row-table.bold {
  font-weight: bold;
}
.row-table .div-td{
  padding: 8px 5px 15px 8px;
  border-right: 1px solid #959595;
}


.row-table .div-td a {
  text-decoration: none;
  color: #000000;
}

.row-table:nth-child(odd){background:#FFFFFF;}
.row-table:nth-child(even){background:#EBEBEB; }

.bg-pink{
  background-color: rgba(239, 10, 21, .1) !important;
}
/*END table list*/
.bg-blue{
  background-color: #C9E9EA !important;
}
.bg-cobalt{
  background-color: #9acbcc !important;
}
.bg-dark-blue{
  background-color: #128186;
}
.bg-white{
  background-color: #ffffff;
}

.bg-yellow{
  background-color: #e3c400 !important;
}

.bg-green-row {
  background-color: #28a745c9!important;
}

.bg-yellow-row{
  background-color: rgba(236, 240, 35, 0.1)!important;
}

.w-3{
  width: 3%;
  max-width: 3%;
}

.w-4{
  width: 4%;
  max-width: 4%;
}

.w-5{
  width: 5%;
  max-width: 5%;
}
.w-6{
  width: 6%;
  max-width: 6%;
}
.w-7{
  width: 7%;
  max-width: 7%;
}
.w-8{
  width: 8%;
  max-width: 8%;
}
.w-10{
  width: 10%;
  max-width: 10%;
}
.w-12{
  width: 12%;
  max-width: 12%;
}
.w-15{
  width: 15%;
  max-width: 15%;
}
.w-10{
  width: 10%;
  max-width: 10%;
}

.w-20{
  width: 20%;
  max-width: 20%;
}

.w-25{
  width: 25%;
  max-width: 25%;
}

.w-27{
  width: 27%;
  max-width: 27%;
}
.w-33{
  width: 33%;
  max-width: 33%;
}

.w-50{
  width: 50%;
  max-width: 50%;
}
.row-detail {
  border-left: 1px solid #959595;
  border-right: 1px solid #959595;
}

.form-relationship-button{
  border-bottom: 1px solid #959595;
}

.row-detail.radius {
  border-radius: 20px;
}
.row-detail .form-control {
  color: #000000;
  padding: initial;
}
.row-detail label {
  padding-left: 0px;
  margin-bottom: 0rem;
}
.row-detail input.form-control:disabled {
  border-bottom: none;
}

/*TABS REL detail*/
.row-detail li.tab-list-item, .modal-body li.tab-list-item{
    color:#00000069 !important;
    width: calc(33.33333% - 10px);
    border: 2px solid #ffffff;
    border-radius: 10px 10px 0 0;
    background-color: unset;
    cursor: pointer;
    margin-right: 10px;
    border-bottom: none;
    font-size: 17px;
    padding: 15px 15px;
    font-weight: bold;
}
.row-detail li.tab-list-item:hover, .modal-body li.tab-list-item:hover{
    text-decoration: underline;
}
.row-detail li.tab-list-active,.modal-body li.tab-list-active{
    border-color:#ffffff !important;
    background-color: #ffffff;
    opacity: unset;
    border-bottom: none;
    color:#000000 !important;
    text-decoration: underline;
}
.row-detail ul.nav-tabs, .modal-body ul.nav-tabs{
  border-bottom: 0;
}
.row-detail .tab-rel, .modal-body .tab-rel{
  background-color: #ffffff;
  padding: 15px;
}
.row-detail .btn-confirm, .modal-body .btn-confirm{
  background-color: #C9E9EA;
  border-color: #128186;
  color: #128186;
}
.row-detail .header-btn button,.row-detail.header-btn a, .modal-body .header-btn button,.modal-body .header-btn a {
  font-size: 0.75em;
}
.row-detail .header-table, .modal-body .header-table{
  background-color: #DEDEDE;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 15px;
}
.row-detail .header-table div, .modal-body .header-table div{
  padding: 10px 5px 10px 8px;
  border-right: 1px solid #ffffff;
}

.row-detail .row-table, .modal-body .row-table{
  display: flex;
  font-size: 0.75em;
  background-color: #ffffff;
  border-bottom: 1px solid #959595;
  border-left: 1px solid #959595;
}
.row-detail .row-table:hover{
  background-color: #ccc;
}
.row-detail .row-table .div-td, .modal-body .row-table .div-td{
  padding: 10px 5px 10px 8px;
  border-right: 1px solid #959595;
}
 .body-table{
  font-size: 0.8em;
}
.row-detail .body-table .row, .modal-body .body-table .row{
  border-bottom: 1px solid #666666;
}
.row-detail .body-table .div-td, .modal-body .body-table .div-td{
  border-right:1px solid #666666;
}
.row-detail .body-table .row:nth-child(even){
  background-color: #ffffff;
}
.row-detail .body-table .row:nth-child(odd){
  background-color: #F4F4F4;
}
.row-detail .tabs, .modal-body .tabs{
  margin-top: 15px;
}


.modal-body li.tab-list-item{
  font-size: 0.85em;
}
/*FINE TABS REL detail*/

.modal-create{
  z-index: 98 !important;
}

/*POPUP*/

.react-confirm-alert-overlay{
 background-color: rgba(0, 0, 0, 0.5);
}

.popup{
  width: 500px;
  padding: 15px;
  background-color: #ffffff;
}

.popup-large {
  width: 800px;
}
.popup-title-alert{
  color: #F0BA60
}
.popup-title-error{
  color: #FF7B79
}
.popup-title-success{
  color: #9DD398
}
.popup-title-delete{
  color: #FF7B79
}
.popup-title-info{
  color: #78AACA
}
/*FINE POPUP*/

.popup.communication {
  width: 700px;
}

.popup.communication.sid .col {
  font-size: 14px;
}

.react-datepicker-wrapper{
  width: 100%;
}



/*MODAL CUSTOM*/
.bar{
background: linear-gradient(270deg, #1070B3 0%, #1A7FBD 10.98%, #0B835A 42.49%, #18A44A 57.33%, #BF3C2A 89.45%, #F16D3B 100%);
  height: 9px;
}
.modal-header{
  border-bottom: none;
  padding-left: 27px;
  padding-right: 27px;
  display: flex;
 }
.modal-header .btn-close, .popup .close{
  color:#128186;
  font-size: 1rem;
  align-self: flex-start;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23128186%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
}
.modal-title, .modal-body .show-grid h3{
  font-weight: 300;
}
.modal-title h4{
  font-weight: 800;
}
.modal-body{
  padding-right: 0;
  padding-left: 0;
}
.modal-body .show-grid{
  padding: 12px;
}
.modal-footer {
  border-top: none;
  display: block;
}
/*end MODAL CUSTOM*/

.text-danger{font-size: 12px; max-height: 40px;}

.table-delegate, .table-balance{ max-height: 160px; min-height: 80px; overflow-y: scroll}
/* CONFIGURAZIONE SID */
.image-upload>input {
  display: none;
}
.image-upload img{
   margin-top: 150%;
   cursor: pointer;
}

/*SUBJECT*/
.form-group .certificate {
  font-size: 14px;
}
.form-group .certificate .file{
  font-size: 13px;
  margin-left: 40px;
  font-weight: bold;
}
.form-group .certificate svg{
  margin-right: 10px;
}

.btn.disabled:not(.btn-link), .btn:disabled:not(.btn-link) {
  opacity: .3;
  background-color: #128186;
  border-color: #128186;
  color:white;
}

input[inputmode="numeric"]{
  text-align: right;
}

.form-group label {
  font-size: 16px;
}

.react-datepicker-popper {
  z-index: 999;
}

.modal-40w {
  max-width: initial;
  width: 40% !important;
}
.modal-40w .modal-body{
  padding: 16px 30px;
}
.modal-60w {
  max-width: initial;
  width: 60% !important;
}
.modal-60w .modal-body{
  padding: 16px 30px;
}
.modal-80w {
  max-width: initial;
  width: 80% !important;
}
.containerPopOver {
  overflow: initial;
  display: flex;
  justify-content: space-evenly;
}
.containerPopOver .pdf, .xml, .recepit .txt, .dgn, .zip, .balance, .photo{
  position: relative;
}

.containerPopOver .photo:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .photo:hover:before{
  visibility: visible;
  font-weight: normal;
}

.containerPopOver .pdf:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .pdf:hover:before{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .txt:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .txt:hover:before{
  visibility: visible;
  font-weight: normal;
}

.containerPopOver .recepit:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .recepit:hover:before{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .dgn:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .dgn:hover:before{
  visibility: visible;
  font-weight: normal;
}

.containerPopOver .xml:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .xml:hover:before{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .zip:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .zip:hover:before{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .balance:hover:after{
  visibility: visible;
  font-weight: normal;
}
.containerPopOver .balance:hover:before{
  visibility: visible;
  font-weight: normal;
}

.containerPopOver .photo:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica FOTOGRAFIA";
  position: absolute;
  width: 300px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 130%;
  left: -1000%;
  color: #fff;
  text-align: center;
}

.containerPopOver .photo:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 90%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .pdf:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica STAMPA COMUNICAZIONE PDF";
  position: absolute;
  width: 300px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 130%;
  left: -1000%;
  color: #fff;
  text-align: center;
 }

.containerPopOver .pdf:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 90%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .xml:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica COMUNICAZIONE XML";
  position: absolute;
  width: 300px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 90%;
  left: -50%;
  color: #fff;
  text-align: center;
}

.containerPopOver .xml:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .recepit:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica RICEVUTA";
  position: absolute;
  width: 200px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 90%;
  left: -50%;
  color: #fff;
  text-align: center;
}

.containerPopOver .recepit:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .txt:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica COMUNICAZIONE TXT";
  position: absolute;
  width: 300px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 90%;
  left: -50%;
  color: #fff;
  text-align: center;
}

.containerPopOver .txt:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .dgn:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica DIAGNOSTICO";
  position: absolute;
  width: 200px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 130%;
  left: -800%;
  color: #fff;
  text-align: center;
}

.containerPopOver .dgn:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}


.containerPopOver .zip:after{
  visibility: hidden;
  background: #128186;
  content: "Scarica COPPIA FILE PECAT";
  position: absolute;
  width: 300px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 130%;
  left: -900%;
  color: #fff;
  text-align: center;
}

.corpro .containerPopOver .zip:after{
  content: "Scarica COMUNICAZIONE ZIP";
}

.containerPopOver .zip:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.containerPopOver .balance:after{
  visibility: hidden;
  background: #128186;
  content: "Disabilitato per la tipologia di rapporto selezionato";
  position: absolute;
  max-width: 400px;
  height: 60px;
  padding: 4px;
  border-radius: 15px;
  border: 1px solid #128186;
  bottom: 90%;
  left: -50%;
  color: #fff;
  text-align: center;
}

.containerPopOver .balance:before {
  visibility: hidden;
  position: absolute;
  content: '';
  bottom: 70%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #128186 transparent transparent transparent;
}

.margin-auto{
  margin: auto;
}

.margin-top-2{
  margin-top: 2%;
}

.row-payment {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
}

.borde-bottom-0{
  border-bottom: 0 !important;
}

[data-tip] {
  position:relative;

}
[data-tip]:before {
  content:'';
  /* hides the tooltip when not hovered */
  display:none;
  content:'';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 5px solid #128186;
  position:absolute;
  color:#128186;
  top:-8.8px;
  left:-10px;
  z-index:9999999;
  font-size:0;
  line-height:0;
  width:0;
  height:5px;
}
[data-tip]:after {
  display:none;
  content:attr(data-tip);
  position:absolute;
  top:-40px;
  left:-100px;
  padding:0px 8px;
  background:#128186;
  color:#ffffff;
  z-index:99999999;
  font-size: 0.75em;
  font-weight: lighter;
  height: 30px;
  line-height:30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space:nowrap;
  word-wrap:normal;
  box-shadow: 0px 2px 0px #128186;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
  display:block;
}

.row-table-without-hover{
  cursor: default !important;
}

.row-table-without-hover:hover {
  font-weight: unset;
}

.modal-subject{
  width: 90%;
  max-width: 90%;
}

.width-60{
  width: 60%;
  max-width: 60%;
}

.width-75{
  width: 75%;
  max-width: 75%;
}
.width-80{
  width: 80%;
  max-width: 80%;
}

.width-90{
  width: 90%;
  max-width: 90%;
}
.hidden{
  display: none !important;
}

.h300Scroll{
  max-height: 300px !important;
  overflow-y: auto;
  text-align:left;
}

.subrow{
  height: auto !important;
}

/**FIXME: rimuovere altezza fissa e testare**/
.header-btn-relationship{
  width: 100%;
  display: flex;
  height: 90px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 0px !important;
  text-align: left;
}

.dropdown.dropdown-lg .dropdown-menu {
  margin-top: -1px;
  padding: 6px 20px;
}
.input-group-btn .btn-group {
  display: flex !important;
}
.btn-group .form-horizontal .btn[type="submit"] {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.form-group .form-control:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media screen and (min-width: 768px) {
  #adv-search {
    width: 500px;
    margin: 0 auto;
  }
  .dropdown.dropdown-lg {
    position: static !important;
  }
  .dropdown.dropdown-lg .dropdown-menu {
    min-width: 500px;
  }
}

.margin-left-5px{
  margin-left: 5px !important;
}

.padding-bottom-4px{
  padding-bottom: 4px;
}

.padding-bottom-15px{
  padding-bottom: 15px;
}

.row-search{
  height: 60px;
}

div.parent {
  position: relative;
}

div.arcoOperationSearchBar {
  position: absolute;
  right:10%;
  width: 45%;
  margin-bottom: 15px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  z-index: 1;
  padding-top: 10px;
}

div#relationships-search-bar {
  position: sticky;
  width: 40%;
  height: 120px;
  margin-bottom: 15px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  z-index: 1;
  padding-top: 10px;
}

div.searchBarArcoRegList{
  background-color: #fff;
  background-clip: padding-box;
}

select {
  background: transparent;
  border: none;
  padding: 6px 12px;
}
button {
  padding: 6px 12px;
  background: transparent;
  border: none;
}
.input-group-addon {
  padding: 0!important;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.no-result{
  color: #128186;
  font-size: 1.2em;
  font-weight: bold;
}

.green-label{
  color: #128186;
  font-weight: bold;
}

.customerBox-dashboard{
  left: -170% !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  border-bottom: none !important;
}

.margin-left-2-percent{
  margin-left: 2% !important;
}

.color-red{
  color: firebrick;
}

.header-btn-history {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-top: 2%;
}
.float-end {float: right}
.pointer {cursor: pointer;}

.admin-table{
  margin-right: 2%;
  margin-left: 2%;
}

.bold{
  font-weight: bold;
}

.assistanceBox{
  margin-top: 5%;
}

.text-x-small{
  font-size: x-small;
}
.text-small{
  font-size: small;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}

.margin-bottom-2perc{
  margin-bottom: 2%;
}



.text-red{
  color: darkred;
}

.corpro .form-check-label {
  font-size: 1.5em !important;
}


.line-around{
  width: 100%; 
  text-align: center; 
  border-bottom: 2px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.line-around span{
  background:#fff; 
  padding:0 10px;
}

.blue-results-modal{
  max-width:600px;
}
.blue-results-modal .modal-body{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.hide-pass {
  -webkit-text-security:disc;
  -moz-text-security:disc;
}

.arcoCheckbox{
  height:10px;
}

.hide-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hide-scrollbar::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.balance-year-dropdown{
  font-size: 1.3rem;
}

.columnResizer {
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  padding: 0 0 0 0;
  margin: 0;
  border: none;
}

.vh-75{
  height: 75vh;
}
.pulse {
  background-color: red;
  border-radius: 5px;
  position: relative;
  animation: animate 3s linear infinite
}


@keyframes animate {
  0% {
      box-shadow: 0 0 0 0 rgb(255, 109, 74, 0.7), 0 0 0 0 rgb(255, 109, 74, 0.7)
  }
  
  40% {
      box-shadow: 0 0 0 10px rgb(255, 109, 74, 0), 0 0 0 0 rgb(255, 109, 74, 0.7)
  }

  80% {
      box-shadow: 0 0 0 10px rgb(255, 109, 74, 0), 0 0 0 10px rgb(255, 109, 74, 0)
  }
  
  100% {
      box-shadow: 0 0 0 0 rgb(255, 109, 74, 0), 0 0 0 10px rgb(255, 109, 74, 0)
  }
}


.form-group {
  margin-bottom: 1rem;
}