.checkbox-column>div {
    text-align: center;
}

.h-95 {
    height: 95% !important;
}

.resizer-area{
    cursor: col-resize!important;
}

 .table-row {
    text-overflow: ellipsis;
}

.cell-input[type="number"]{
    text-align: right;
}

.cell-input[type="number"]::-webkit-outer-spin-button,
 .cell-input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

.cell-input[type="date"]{
    text-align: center;
}
.cell-input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}
/* 
.cell-input[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
} */

.cell-input{
    border: 1px solid black;
    -moz-appearance: textfield!important;
    width: 98%;
}


.star-table-container{
    max-height: calc(80vh - 90px);
    border: 1px solid;
}

@media screen and (max-height: 720px){
    .star-table-container{
        max-height: calc(60vh - 90px);
    }
}


.css-npntw3-CELL_CONTAINER_STYLE-Cell.pin-left, .css-npntw3-CELL_CONTAINER_STYLE-Cell.pin-right,
.css-oada4b-HEADER_CELL_CONTAINER_STYLE-HeaderCell.pin-left, .css-oada4b-HEADER_CELL_CONTAINER_STYLE-HeaderCell.pin-right {
    right: 0;

}