

.configurazioneSID {
    padding: 0px 15px 0px 15px;
    border: 1px solid #c9e9ea;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background: #e6f4f4;
    box-shadow: inset -5px 5px 9px 0px rgba(18, 129, 134, 0.2);
}
.soggetto h5 svg, .configurazioneSID h5 svg{
    margin-right: 10px;
}

.btn_carica {
    margin-left: 10px;
}


.email-container {
    border-top: 10px solid #C9E9EA;
}

.email-container .col-md-5 {
    margin-top: 39px;
}

.email-container .form-group {
    margin-top: 10px;
    border-top-left-radius: 17px;
}

.email-container .row {
    padding: 10px;
}

.certificateFiles{
    font-weight: bold;
    text-decoration: underline;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    padding-right: 20px;

}
.certificateFiles svg{
    margin-left: 10px;
}
.certificatiLoaded{
    margin-top: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: #8e8e8e;
    padding-right: 15px;
}
.certificatiLoaded svg {
    margin-right: 5px;
}
.certificatiLoaded-error {
    color: #dc3545;
}


.visibleSubject-enter {
    opacity: 0.01;
    height: 0px;
    overflow: hidden;
}

.visibleSubject-enter-active {
    opacity: 1;
    height: 420px;
    transition: all 300ms;
}

.visibleSubject-exit {
    opacity: 0;
    height: 420px;
    transition: all 300ms;
}

.visibleSubject-exit-active {
    opacity: 0;
    height: 0px;
    transition: all 300ms;
}

